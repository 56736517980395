<template>
  <v-sheet>
    <v-container fluid>
      <v-row justify="center" align="center" class="height100">
        <v-col xs="12" md="8" lg="6">
          <div v-if="isLoading" data-test="skeleton-loader-section">
            <v-skeleton-loader type="image" :height="500"></v-skeleton-loader>
          </div>
          <div v-else>
            <div
              class="text-center"
              v-if="no_selected_property"
              data-test="no-property-found"
            >
              <h1>{{ $t('No Property Details') }}!!</h1>
              <p>
                {{ $t('Kindly, Scan QR Code Again') }}
              </p>
            </div>
            <v-card v-else elevation="16" data-test="main-form-section">
              <v-card-item class="re-orange-btn">
                <v-card-title class="text-white">
                  {{ $t('Report Property Issue') }}
                </v-card-title>
                <template v-slot:append>
                  <v-btn
                    variant="text"
                    class="lang-switch-btn"
                    @click="switchLanguage"
                  >
                    {{ availableLangTitle }}
                  </v-btn>
                </template>
              </v-card-item>
              <v-form ref="form" @submit.prevent="saveTicket">
                <v-card-text>
                  <div class="mb-5 d-flex">
                    <span class="custom-text"> {{ $t('Property') }} : </span>
                    <span class="px-2" data-test="property-id">
                      {{ ticket.property.property_name }}</span
                    >
                  </div>
                  <v-select
                    variant="outlined"
                    v-model="ticket.selectedUnits"
                    multiple
                    :items="units"
                    :rules="[atLeastoneItemRequiredRule]"
                    item-title="unit_number"
                    item-value="id"
                    :label="$t('Unit')"
                    class="mb-1"
                    ref="unit-select-input"
                  />
                  <div class="custom-text mb-2">{{ $t('Issues') }} :</div>
                  <v-chip-group
                    column
                    multiple
                    v-model="ticket.selectedIssues"
                    selected-class="re-orange-btn"
                    ref="issues-select-input"
                  >
                    <v-chip
                      v-for="option in issuesOptions"
                      :key="option.id"
                      :value="option.id"
                      label
                    >
                      {{ option.name }}
                    </v-chip>
                  </v-chip-group>
                  <div
                    v-if="!isValidIssues && showSelectedIssuesError"
                    class="custom-error mt-1 mx-5"
                  >
                    {{ $t('Kindly, Select at least one issue') }}
                  </div>
                  <v-textarea
                    v-model="ticket.notes"
                    variant="outlined"
                    :label="$t('Notes')"
                    class="mt-5"
                    ref="notes-textarea"
                  />
                  <vue-tel-input
                    v-model="ticket.creator_phone_number"
                    mode="international"
                    validCharactersOnly
                    :styleClasses="{
                      'input-error': !isValidPhone && isTouched,
                    }"
                    @on-input="
                      (phoneNumber, phoneDetails) =>
                        updatePhoneDetails(phoneNumber, phoneDetails)
                    "
                    @blur="isTouched = true"
                    ref="phone-input"
                  ></vue-tel-input>
                  <div
                    v-if="!isValidPhone && isTouched"
                    class="custom-error mt-1 mx-5"
                  >
                    {{ $t('Phone Number is required') }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-row justify="center">
                    <v-btn
                      variant="text"
                      type="submit"
                      class="re-orange-btn"
                      :loading="isLoading"
                    >
                      {{ $t('Submit') }}
                    </v-btn>
                  </v-row>
                </v-card-actions>
              </v-form>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';

import propertyMaintenanceService from '../services/propertyMaintenance';

import LanguageSwitchMixin from '@/mixins/LanguageSwitchMixin';
import validationRulesMixin from '@/mixins/validationRulesMixin';

import 'vue-tel-input/vue-tel-input.css';
export default {
  name: 'AddPropertyMaintenanceTicket',
  components: {
    VueTelInput,
  },
  mixins: [validationRulesMixin, LanguageSwitchMixin],
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      no_selected_property: true,
      isLoading: false,
      isLoadingPropertyDetails: false,
      isLoadingIssuesOptions: false,
      ticket: {
        property: null,
        notes: '',
        creator_country_code: '',
        creator_phone_number: null,
        selectedIssues: [],
        selectedUnits: [],
      },
      units: [],
      issuesOptions: [],
      isValidPhone: false,
      isTouched: false,
      showSelectedIssuesError: false,
    };
  },
  watch: {
    '$i18n.locale': async function () {
      await this.fetchData();
    },
  },
  computed: {
    isValidIssues() {
      return !!this.ticket.selectedIssues.length;
    },
    availableLangTitle() {
      return this.$i18n.locale === 'en' ? 'العربية' : 'English';
    },
  },
  methods: {
    async fetchPropertyDetails(propertyId) {
      try {
        this.isLoadingPropertyDetails = true;
        const res = await this.$processReq(
          propertyMaintenanceService.getPropertyDetails(propertyId),
          false,
          false,
        );
        this.units = res.data.body.unit_details;
        this.ticket.property = res.data.body;
        this.no_selected_property = false;
        this.isLoadingPropertyDetails = false;
      } catch (err) {
        this.isLoadingPropertyDetails = false;
        console.log('Error: Unable to get property details - ', err);
      }
    },
    async loadIssuesOptions() {
      try {
        this.isLoadingIssuesOptions = true;
        let params = {
          limit: 100, // 100 value of limit to get all issues list
          offset: 0,
        };
        const res = await this.$processReq(
          propertyMaintenanceService.getIssuesOptions(params),
          false,
          false,
        );
        this.issuesOptions = res.data.body.results;
        this.isLoadingIssuesOptions = false;
      } catch (err) {
        this.isLoadingIssuesOptions = false;
        console.log('Error: Unable to get issues list - ', err);
      }
    },
    async fetchData() {
      const params = this.$route.params;
      if (params?.property_id) {
        try {
          this.isLoading = true;
          await Promise.all([
            this.fetchPropertyDetails(params.property_id),
            this.loadIssuesOptions(),
          ]);
          this.isLoading = false;
        } catch (err) {
          this.isLoading = false;
          console.log('Error: Unable to fetch data and initialize inputs', err);
        }
      }
    },
    async saveTicket() {
      if (!this.isTouched) {
        this.isTouched = true;
      }
      if (!this.ticket.selectedIssues.length) {
        this.showSelectedIssuesError = true;
      }
      const { valid } = await this.$refs.form.validate();
      if (valid && this.isValidIssues && this.isValidPhone) {
        try {
          let data = {
            property: this.ticket.property.id,
            note: this.ticket.notes,
            creator_country_code: this.ticket.creator_country_code,
            creator_phone_number: this.ticket.creator_phone_number.replaceAll(
              ' ',
              '',
            ),
            maintenance_ticket_status: 1, // 1 refers to the status of ticket which will be [new]
            fix_types: this.ticket.selectedIssues,
            units: this.ticket.selectedUnits,
          };
          this.isLoading = true;
          await this.$processReq(
            propertyMaintenanceService.addNewMaintenanceTicket(data),
            true,
          );
          this.isLoading = false;
          this.resetTicketForm();
        } catch (err) {
          this.isLoading = false;
          console.log('Error: Unable to add new maintenance ticket - ', err);
        }
      }
    },
    updatePhoneDetails(phoneNumber, phoneDetails) {
      this.isValidPhone = !!phoneDetails.valid;
      this.ticket.creator_country_code = phoneDetails.countryCode;
    },
    resetTicketForm() {
      this.ticket = {
        ...this.ticket,
        creator_country_code: '',
        notes: '',
        creator_phone_number: null,
        selectedIssues: [],
        selectedUnits: [],
      };
      this.showSelectedIssuesError = false;
      this.isTouched = false;
    },
  },
};
</script>
<style>
.v-overlay--active:before {
  opacity: 0;
}
</style>
<style scoped>
.re-orange-btn {
  background-color: #f57829 !important;
  color: #fff;
}
.re-orange {
  color: #f57829 !important;
}
.custom-text {
  color: rgba(0, 0, 0);
  font-size: 16px;
}
.v-textarea::v-deep textarea {
  padding: 12px 8px;
}
.custom-error {
  font-size: 12px;
  color: #b00020 !important;
}
.input-error {
  outline-color: #b00020;
  outline-style: auto;
}
.lang-switch-btn {
  background-color: #fff !important;
  color: #f57829;
}
.v-input.v-locale--is-rtl::v-deep div.v-messages__message {
  text-align: right;
}
.v-container.v-container--fluid.v-locale--is-rtl::v-deep
  .vti__dropdown-list.above {
  right: -1px;
  max-width: 250px;
}
</style>
