import { isEqual } from 'lodash';
import { createRouter, createWebHistory } from 'vue-router';

import AddPropertyMaintenanceTicket from '@/views/AddPropertyMaintenanceTicket.vue';
import CheckoutReqPay from '@/views/CheckoutReqPay';

const routes = [
  {
    path: '/:lang',
    children: [
      {
        path: 'checkout/:session_id',
        name: 'checkout-sdk',
        meta: {
          page_title: 'Checkout',
          plugin: 'checkout',
        },
        component: CheckoutReqPay,
      },
      {
        path: 'pos/attempt-detail/:attempt_id',
        name: 'payment-attempt-detail',
        meta: {
          plugin: 'pos',
        },
        component: () =>
          import(
            /*webpackChunkName: "AttemptDetails"*/ '../views/AttemptDetails.vue'
          ),
      },
      {
        path: 'property-maintenance/:property_id',
        name: 'add-property-maintenance-ticket',
        meta: {
          page_title: 'Report Property Issue',
        },
        component: AddPropertyMaintenanceTicket,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (isEqual(to, from)) return;
  next();
});

export default router;
