export default {
  methods: {
    requiredRule(v) {
      return !!v || this.$t('Field is required');
    },
    stringRule(v) {
      if (/[^0-9]/.test(v)) return true;

      return this.$t('Can not contain numbers');
    },
    maxLength(ruleValue) {
      return v => {
        if (v && v.length <= ruleValue) return true;

        return `${this.$t('Must be maximum')} ${ruleValue} characters`;
      };
    },
    minLength(ruleValue) {
      return v => {
        if (v && v.length >= ruleValue) return true;

        return `${this.$t('Must be at least')} ${ruleValue} characters`;
      };
    },
    emailRule(v) {
      if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(v)) return true;

      return this.$t('Must be a valid e-mail');
    },
    numericRule(v) {
      if (v && !isNaN(v)) return true;

      return this.$t('Must contain only numbers');
    },
    greaterThan(ruleValue) {
      return v => {
        if (v && !isNaN(v) && Number(v) > Number(ruleValue)) return true;

        return `${this.$t('Must be greater than')} ${ruleValue}`;
      };
    },
    lessThan(ruleValue) {
      return v => {
        if (v && !isNaN(v) && Number(v) < Number(ruleValue)) return true;

        return `${this.$t('Must be less than')} ${ruleValue}`;
      };
    },
    atLeastoneItemRequiredRule(v) {
      return !!v.length || this.$t('Kindly, Select at least one item');
    },
  },
};
